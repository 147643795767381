<template>
  <div class="orders-component">

    

    <div class="content">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col">
            </div>
            <div class="col-1">
              <input type="text" placeholder="number of days" class="form-control" v-model="days">
            </div>
            <div class="col-auto">
              <a class="vave-btn" href="#" @click.prevent="fetchDevicesData()">Filter</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div v-if="devices">
            <div class="background mt-3">
              <div class="row px-3">
                <div class="col-12 p-3">
                  <div class="row">
                    <div class="col">
                      <div class="d-flex">
                        <h6>Top devices</h6>
                        <div class="ms-2 small pale">in the past {{days}} days</div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <div class="row" style="width: 400px;">
                        <div class="col-12">
                          <div class="my-1 d-flex align-items-center" style="font-size:0.7em;">
                            <i class="fa fa-mobile"></i>
                              <div class="progress w-100 mx-1">
                                <div class="progress-bar mobile" role="progressbar" :style="'width:'+all_traffic_mobile_percentage+'%;font-size:0.8em;'" >{{all_traffic_mobile_percentage}}%</div>
                                <div class="progress-bar desktop" role="progressbar" :style="'width:'+all_traffic_desktop_percentage+'%;font-size:0.8em;'">{{all_traffic_desktop_percentage}}%</div>
                              </div>
                            <i class="fa fa-display"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <device-table :devices="top_all_traffic" :total="all_traffic_desktop + all_traffic_mobile" :showother="true" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="background mt-3">
              <div class="row px-3">
                <div class="col-12 p-3">
                  <div class="row">
                    <div class="col">
                      <div class="d-flex">
                        <h6>Top Devices with at least one order</h6>
                        <div class="ms-2 small pale">in the past {{days}} days</div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <div class="row" style="width: 400px;">
                        <div class="col-12">
                          <div class="my-1 d-flex align-items-center" style="font-size:0.7em;">
                            <i class="fa fa-mobile"></i>
                              <div class="progress w-100 mx-1">
                                <div class="progress-bar mobile" role="progressbar" :style="'width:'+orders_traffic_mobile_percentage+'%;font-size:0.8em;'" >{{orders_traffic_mobile_percentage}}%</div>
                                <div class="progress-bar desktop" role="progressbar" :style="'width:'+orders_traffic_desktop_percentage+'%;font-size:0.8em;'">{{orders_traffic_desktop_percentage}}%</div>
                              </div>
                            <i class="fa fa-display"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <device-table :devices="top_traffic_with_orders" :total="orders_traffic_desktop + orders_traffic_mobile" :showother="true" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="background mt-3">
              <div class="row px-3">
                <div class="col-12 p-3">
                  <h6>Bots detected</h6>
                  <div class="row">
                    <div class="col">
                      <device-table :devices="bots" :total="null" :showother="false" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="!devices">
            <div class="col">
              <div class="mt-4">
                <div class="loading-placeholder">
                  <div class="spinner-border spinner-border-sm ms-2 big-spinner light-spinner" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="filters">
        <div class="row align-items-center justify-content-end">
          <div class="col-auto">
            <a class="vave-btn" href="#" @click.prevent="showFilters=!showFilters">
              Filters
              &nbsp;
              <i class="fa-regular fa-filter-list"></i>
            </a>
          </div>
        </div>
        <div class="filters-box" :class="{'show': showFilters}">
          <div class="row pale">
            <div class="col-6 mt-2">
              From
              <input class="form form-control" v-model="parameters.from" type="date" />
            </div>
            <div class="col-6 mt-2">
              To
              <input class="form form-control" v-model="parameters.to" type="date" />
            </div>
          </div>
          <div class="row align-items-end">
            <div class="col mt-3">
              <a href="#" class="blue" @click.prevent="()=>{resetFilters(); showFilters=false;}">Reset</a>
            </div>
            <div class="col-auto mt-3">
              <button class="vave-btn btn-small btn-green" @click="()=>{fetchData(); showFilters=false;}" :disabled="!dataReady">Apply</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2" v-if="Object.keys(appliedFilters).length">
        <div class="col">
          <div class="filters-applied">
            <div class="label">ACTIVE FILTERS:</div>
            <div class="pill" v-for="filter, key in appliedFilters" :key="'filt-'+key">
              {{key}}: {{filter}}
              <span @click="unsetFilter(key)" class="unset-filter">
                <i class="fas fa-times"></i>
              </span>
            </div>
            <a class="ps-2 blue" href="#" @click.prevent="resetFilters()">Reset</a>
          </div>
        </div>
      </div>

      <div class="row mt-3" v-if="dataReady">
        <div class="col">
          <div class="background">
            <div class="row">
              <div class="col">
                <ApexChart v-if="dataReady" :key="chartKey" type="bar" height="450" :options="chartOptions" :series="series" /> 
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="px-4 pb-4" style="color: darkgrey;">
                  Comparing past year to <strong>{{from}}</strong> - <strong>{{to}}</strong>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
      <div class="row" v-if="!dataReady">
        <div class="col">
          <div class="mt-4">
            <div class="loading-placeholder">
              <div class="spinner-border spinner-border-sm ms-2 big-spinner light-spinner" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import ApexChart from "vue-apexcharts";
import axios from "../http.js";
import moment from "moment";
import DeviceTable from "./Visitors/DeviceTable";

export default {
  data() {
    return {
      all_traffic_mobile: null,
      all_traffic_desktop: null,
      orders_traffic_mobile: null,
      orders_traffic_desktop: null,

      all_traffic_mobile_percentage: 0,
      all_traffic_desktop_percentage: 0,
      orders_traffic_mobile_percentage: 0,
      orders_traffic_desktop_percentage: 0,

      days: 30,
      bots: [],
      showFilters: false,
      images: {
        paypal: require("./../assets/paypal_small.png"),
      },
      appliedFilters: {},
      perPage: 15,
      parameters: {
        from: "",
        to: "",
      },
      // Devices
      devices: null,
      // CHART
      dataReady: false,
      timer: null,
      latestCall: null,

      chartKey: 1,
      series: [{
        name: 'Past Week AVG Visitors per hour',
        data: []
        }, {
          name: 'Past Year AVG Visitors per hour',
          data: []
        }],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
          height: 150,
          type: 'bar',
          animations: {
            enabled: true,
          },
        },
        stroke: {
          curve: 'smooth',
        },
        fill: {
          colors: [
            '#40a0fc',
            '#5e87ad',
          ]
        },
        colors:[
          '#40a0fc',
          '#5e87ad',
        ],
        plotOptions: {
          bar: {
            borderRadius: 0,
            columnWidth: '90%',
          }
        },
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          show: false,
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: function (val) {
              return parseInt(val).toString();
            },
          },
        },
        xaxis: {
          categories: [],
        
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          
        },
        yaxis: {
          
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          }
        },
      annotations: {
        yaxis: [
          {
            y: 0,
            borderColor: '#40a0fc',
            label: {
              borderColor: '#40a0fc',
              style: {
                color: '#fff',
                background: '#40a0fc',
              },
              text: 'Past Week Average',
            },
          },
          {
            y: 0,
            borderColor: '#5e87ad',
            label: {
              borderColor: '#5e87ad',
              style: {
                color: '#fff',
                background: '#5e87ad',
              },
              text: 'Past Year Average',
            },
          },
        ],
      },
      },
    };
  },
  components: {
    ApexChart, 
    DeviceTable
  },
  mounted() {
    this.fetchDevicesData();
    this.fetchData();
  },
  computed:{
    top_all_traffic() {
      return this.devices.all_traffic;
    },
    top_traffic_with_orders() {
      return this.devices.traffic_with_orders;
    },
    from () {
      return this.parameters.from ? this.parameters.from : moment().subtract(7, 'days').format('YYYY-MM-DD');
    },
    to  () {
      return this.parameters.to ? this.parameters.to : moment().subtract(1, 'days').format('YYYY-MM-DD');
    },
  },
  methods: {
    fetchDevicesData(){
      this.devices = null;
      if (!this.days) {
        this.days = 1;
      }
      axios.get("/api/admin/dashboard/stats/devices?days=" + this.days).then((response) => {
        this.devices = response.data;
        //  all_traffic: [{
        // "user_agent": "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6.1 Mobile/15E148 Safari/604.1",
        // "user_agent_count": 124,
        // "browser": "Safari",
        // "platform": "iOS 15.6.1",
        // "device": "mobile", // or 'desktop' / 'tablet' / 'bot'
        // "mobile": true
        // },...
        // sum up all user_agent_count from all the mobile devices
        this.all_traffic_mobile = this.devices.all_traffic.filter(device => device.device == ('mobile')).reduce((a, b) => a + (b['user_agent_count'] || 0), 0);
        this.all_traffic_desktop = this.devices.all_traffic.filter(device => (device.device == 'desktop' || device.device == 'tablet')).reduce((a, b) => a + (b['user_agent_count'] || 0), 0);
        this.all_traffic_mobile_percentage = Math.round((this.all_traffic_mobile / (this.all_traffic_mobile + this.all_traffic_desktop)) * 100);
        this.all_traffic_desktop_percentage = 100 - this.all_traffic_mobile_percentage;

        this.orders_traffic_mobile = this.devices.traffic_with_orders.filter(device => device.device == ('mobile')).reduce((a, b) => a + (b['user_agent_count'] || 0), 0);
        this.orders_traffic_desktop = this.devices.traffic_with_orders.filter(device => (device.device == 'desktop' || device.device == 'tablet')).reduce((a, b) => a + (b['user_agent_count'] || 0), 0);
        this.orders_traffic_mobile_percentage = Math.round((this.orders_traffic_mobile / (this.orders_traffic_mobile + this.orders_traffic_desktop)) * 100);
        this.orders_traffic_desktop_percentage = 100 - this.orders_traffic_mobile_percentage;

        this.bots =  this.devices.all_traffic.filter(device => device.device == ('bot'))

        // this.all_traffic_desktop
        // this.orders_traffic_mobile
        // this.orders_traffic_desktop
      });
    },
    fetchData() {
      this.dataReady = false;
      // Add a cooldown system that prevents a call if the previous call was less than 5 seconds ago
      if (this.latestCall != null && (new Date() - this.latestCall) < 5000) {
        return;
      }
      this.latestCall = new Date();

      // let from = this.parameters.from ? this.parameters.from : moment().subtract(7, 'days').format('YYYY-MM-DD');
      // let to = this.parameters.to ? this.parameters.to: moment().subtract(1, 'days').format('YYYY-MM-DD');

      let numberOfDaysInRange = moment(this.to).diff(moment(this.from), 'days');

      axios.get("/api/admin/dashboard/stats/daily-customers?from="+this.from+"&to="+this.to).then((response) => {
          let chartData = [];
          let chartLabels = [];

          for (const [key, value] of Object.entries(response.data.data)) {
            chartLabels.push(key);
            chartData.push(Math.max(0.5, value/numberOfDaysInRange));
          }

          this.series[0].data = chartData;
          this.chartOptions.xaxis.categories = chartLabels;
          let avg = Math.max(0.5, Math.round(chartData.reduce((a, b) => a + b, 0) / chartData.length));
          this.chartOptions.annotations.yaxis[0].y = avg
          this.chartOptions.annotations.yaxis[0].label.text = 'Hourly Average: ' + avg + ' Visitors'

          let from = moment().subtract(1, 'year').format('YYYY-MM-DD');
          let to = moment().subtract(1, 'days').format('YYYY-MM-DD');

          axios.get("/api/admin/dashboard/stats/daily-customers?from="+from+"&to="+to).then((response) => {
            chartData = [];

            for (const value of Object.values(response.data.data)) {
              chartData.push(Math.max(1, value/365));
            }

            this.series[1].data = chartData.reverse();
            let avg = Math.max(0.5, Math.round(chartData.reduce((a, b) => a + b, 0) / chartData.length));
            this.chartOptions.annotations.yaxis[1].y = avg;
            this.chartOptions.annotations.yaxis[1].label.text = 'Past Year Hourly Average: ' + avg + ' Visitors'


            // this.chartOptions.xaxis.categories = chartLabels.reverse();
            this.dataReady = true;

            this.chartKey++;

            setTimeout(() => {
              this.chartOptions.chart.animations.enabled = false;
            }, 1000);
            // console.log(this.series)
          });
      });
    },
    unsetFilter(key) {
      this.parameters[key] = "";
      this.fetchData();
    },
    resetFilters() {
      this.parameters = {
        from: "",
        to: "",
      };
      this.appliedFilters = {};
      this.fetchData();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.orders-component {
  background: #f1f5f9;
  position: relative;

  .content {
    .table-container {
      .hidden {
        height: 0;
        overflow: hidden;
        transition: 0.2s;
        opacity: 0;
      }

      // shadow
      tbody tr {
        .status {
          font-size: 0.9em;
        }
        &:hover {
          background: #fafafa;
          &.isToday {
            background: #fafafa;
          }

          .hidden {
            height: 22px;
            transition: 0.2s;
            opacity: 1;
          }
        }
        &.isToday {
          background: #f4fbfc;
          .pale {
            color: #999;
          }
        }
      }
    }
  }
  .ucfirst {
    text-transform: capitalize;
  }
  .failedToSyncOrder {
    background: #dd4f4f8a;
    &:hover {
      background: #ea2a2ab6;
    }
  }
  .retrying {
    background: #daa5498a;
    &:hover {
      background: #eab02ab6;
    }
  }
  .filters-box {
    z-index: 4;
    transition: 0.2s;
    position: absolute;
    right: 20px;
    top: 60px;
    max-width: 500px;
    background: #f1f5f9;
    border: 1px solid #e6e6e6;
    padding: 24px;

    pointer-events: none;
    opacity: 0;
    &.show {
      opacity: 1;
      pointer-events: all;
    }
  }

  .filters-applied {
    border-radius: 6px;
    padding: 5px 10px;
    display: flex;
    font-size: 14px;
    align-items: center;
    .label {
      color: #888;
      font-weight: 600;
      margin-right: 16px;
    }
    .pill {
      background: #ffc641;
      padding: 3px 10px 1px;
      border-radius: 8px;
      margin-right: 8px;
      font-size: 0.9em;
      // shadow
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2),
        0 1px 3px 0 rgba(0, 0, 0, 0.19);
      .unset-filter {
        cursor: pointer;
        padding-left: 8px;
      }
    }
  }

  .multiselect__tags {
    border-radius: 0px;
    border: 1px solid #ced4da;
  }

  .floating-summary {
    position: sticky;
    bottom: 0;
    left: 0;
    background: white;
    border: 1px solid #e6e6e6;
    padding: 10px;
    z-index: 3;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    color: #888;
    .pre {
      font-weight: 400;
    }
    .value {
      color: #333;
      font-weight: 600;
    }
  }
}
.background {
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
}

.progress {
  height: 16px;
  .progress-bar {
    &.mobile {
      background:#40a0fc;
    }
    &.desktop {
      background:#5e87ad;
    }
  }
}

</style>
