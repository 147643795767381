
<template>
  <div class="table-container mt-2" v-if="devices">
    <table class="table align-middle">
      <thead class="head">
        <tr>
          <th>Device</th>
          <th>Platform</th>
          <th>User Agent</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="device in topDevices" :key="device.id">
          <td style="max-width: 60px;">
            <div class="d-flex flex-column text-center align-items-center">
              <span v-if="device.device == 'mobile'">
                <i class="fa fa-mobile"></i> 
              </span>
              <span v-else-if="device.device == 'bot'">
                <i class="fa fa-robot"></i> 
              </span>
              <span v-else-if="device.device == 'desktop'">
                <i class="fa fa-display"></i> 
              </span>
              <span v-else-if="device.device == 'tablet'">
                <i class="fa fa-tablet"></i> 
              </span>
              <span v-else>
                <i class="fa fa-question"></i> 
              </span>
              <span class="pale hidden small pt-1" style="width: 80px;">{{device.device}}</span>
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span class="bold blue">{{device.browser}} </span>
              <span class="pale">{{device.platform}}</span>
            </div>
          </td>
          <td>
            <div style="font-size: 0.9em;color:#999;">{{device.user_agent}}</div>
          </td> 
          <td>
            <div class="d-flex flex-column text-center align-items-center" v-if="total">
              {{ Math.round((device.user_agent_count / (total)) * 100) }}%
              <span class="pale hidden small pt-1" style="width: 80px;">{{device.user_agent_count}}</span>
            </div>
            <div class="d-flex flex-column text-center align-items-center" v-else>
              <span class="pale small pt-1" style="width: 80px;">{{device.user_agent_count}}</span>
            </div>
          </td> 
        </tr>
        <tr v-if="showother">
          <td style="max-width: 60px;">
            <div class="d-flex flex-column text-center align-items-center">
              <i class="fa fa-question"></i> 
            </div>
          </td>
          <td>
            <div class="d-flex flex-column">
              <span class="bold blue">Others </span>
              <span class="pale"></span>
            </div>
          </td>
          <td>
            <div style="font-size: 0.9em;color:#999;"></div>
          </td>
          <td>
            <div class="d-flex flex-column text-center align-items-center" v-if="total">
              {{otherDevicesCount}}%
              <span class="pale hidden small pt-1" style="width: 80px;">{{total}}</span>
            </div>
            <div class="d-flex flex-column text-center align-items-center" v-else>
              <span class="pale small pt-1" style="width: 80px;"></span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: ["devices", "total", "showother"],
  computed:{
    topDevices(){
      return this.devices.slice(0, 10)
    },
    otherDevicesCount(){
      let remainingDevices = this.devices.slice(10);
      // Find the percentage of other devices
      let t = remainingDevices.reduce((a, b) => a + (b['user_agent_count'] || 0), 0); 
      return Math.round((t / (this.total)) * 100)
    },
  },
};
</script>

<style lang="scss">
.orders-component {
  background: #f1f5f9;
  position: relative;

  .content {
    .table-container {
      .hidden {
        height: 0;
        overflow: hidden;
        transition: 0.2s;
        opacity: 0;
      }

      // shadow
      tbody tr {
        .status {
          font-size: 0.9em;
        }
        &:hover {
          background: #fafafa;
          &.isToday {
            background: #fafafa;
          }

          .hidden {
            height: 22px;
            transition: 0.2s;
            opacity: 1;
          }
        }
        &.isToday {
          background: #f4fbfc;
          .pale {
            color: #999;
          }
        }
      }
    }
  }
  .ucfirst {
    text-transform: capitalize;
  }
  .failedToSyncOrder {
    background: #dd4f4f8a;
    &:hover {
      background: #ea2a2ab6;
    }
  }
  .retrying {
    background: #daa5498a;
    &:hover {
      background: #eab02ab6;
    }
  }
  .filters-box {
    z-index: 4;
    transition: 0.2s;
    position: absolute;
    right: 20px;
    top: 60px;
    max-width: 500px;
    background: #f1f5f9;
    border: 1px solid #e6e6e6;
    padding: 24px;

    pointer-events: none;
    opacity: 0;
    &.show {
      opacity: 1;
      pointer-events: all;
    }
  }

  .filters-applied {
    border-radius: 6px;
    padding: 5px 10px;
    display: flex;
    font-size: 14px;
    align-items: center;
    .label {
      color: #888;
      font-weight: 600;
      margin-right: 16px;
    }
    .pill {
      background: #ffc641;
      padding: 3px 10px 1px;
      border-radius: 8px;
      margin-right: 8px;
      font-size: 0.9em;
      // shadow
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2),
        0 1px 3px 0 rgba(0, 0, 0, 0.19);
      .unset-filter {
        cursor: pointer;
        padding-left: 8px;
      }
    }
  }

  .multiselect__tags {
    border-radius: 0px;
    border: 1px solid #ced4da;
  }

  .floating-summary {
    position: sticky;
    bottom: 0;
    left: 0;
    background: white;
    border: 1px solid #e6e6e6;
    padding: 10px;
    z-index: 3;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    color: #888;
    .pre {
      font-weight: 400;
    }
    .value {
      color: #333;
      font-weight: 600;
    }
  }
}
.background {
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
}

.progress {
  height: 16px;
  .progress-bar {
    &.mobile {
      background:#40a0fc;
    }
    &.desktop {
      background:#5e87ad;
    }
  }
}

</style>